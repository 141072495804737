import React from "react"
import { Link } from "gatsby"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { splitText } from "@utils"

const TermsOfServicePage = ({ data }) => {
  const { themeColor } = usePageSetup({ themeColor: "bg-pink-light", displayGeneralForm: false })

  return (
    <Layout>
      <Seo title="Terms of service" description="" />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1
              data-aos="stagger"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": "Terms of service.",
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <main>
        <section className="secondary py-clamp-18-35">
          <div className="lg:grid container grid-cols-12">
            <div className="col-start-2 xl:col-start-3 col-span-9">
              <p>
                Access to and use of <Link to={"/"}>www.jkdesign.com</Link> image and text files and data on this
                Website are subject to the following terms and conditions:
              </p>
              <p>
                1. All text, images and data on the <Link to={"/"}>www.jkdesign.com</Link> Website (the “site”) are
                protected by copyright and may be covered by other restrictions as well. JK retains all rights,
                including copyright, in data, images, software, documentation, text, and other information contained in
                these files (collectively, the “materials”). Copyright and other proprietary rights may be held by
                individuals or entities other than, or in addition to, JK.
              </p>
              <p>
                2. The materials are made available for limited non-commercial, personal and educational use only, or
                for fair use as defined in the United States copyright laws. Users may download these files for their
                own non-electronic use, subject to any additional terms or restrictions, which may be applicable to the
                individual file or program. Users must, however, cite the author and source of the materials as they
                would material from any printed work, and the citations should include www.jkdesign.com.
              </p>
              <p>
                JK does not warrant that use of the materials displayed on the site will not infringe the rights of
                third parties not owned by or affiliated with JK. For example, some works may be under copyright by the
                artist or the artist’s heirs holding rights to these works. Such works may not be used in any form; they
                may not be copied or downloaded without prior permission from the holder of the underlying copyright.
              </p>
              <p>
                Copying or redistribution in any manner for commercial use, including commercial publication, or for
                personal gain is strictly prohibited.
              </p>
              <p>
                3. Any unauthorized use of the trademarked, service marked and materials with logos, or any other
                materials, except as authorized in these Terms and Conditions, is strictly prohibited. Many of the
                trademarks, service marks, and logos displayed on <Link to={"/"}>www.jkdesign.com</Link> are registered
                and/or unregistered marks of JK. The Trademarks of third parties may also be displayed on this site.
                Nothing contained in this site should be construed as granting, by implication, estoppel or otherwise,
                any license or right to use any trademarks, service marks or logos displayed on the site without the
                express written permission of JK or any third party that may own them.
              </p>
              <p>
                4. Without limiting the foregoing, all materials on the <Link to={"/"}>www.jkdesign.com</Link> website
                are provided “as is” without a warranty of any kind, either express or implied, including but not
                limited to the implied warranties of merchantability, fitness for a particular use, and/or
                non-infringement. JK assumes no responsibility, and shall not be liable for, any damages to, or viruses
                that may infect, the user’s computer equipment or other property on account of your use of the site or
                your downloading of any materials from the site.
              </p>
              <p>
                5. JK has not reviewed all of the sites linked to <Link to={"/"}>www.jkdesign.com</Link> and is not
                responsible for, nor does it endorse, the content of any off-site pages. Linking to any off-site pages
                or other sites is at the user’s own risk.
              </p>
              <p>
                6. JK may at any time revise these Terms and Conditions by updating this posting located at{" "}
                <Link to={"/terms-of-service"}>www.jkdesign.com/terms-of-service</Link>. You are bound by any such
                revisions and should therefore periodically visit this page to review the then-current Terms and
                Conditions.
              </p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default TermsOfServicePage
